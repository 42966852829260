body {
    font-family: 'Poppins', sans-serif;
}


nav {
    flex: 1;
    text-align: right;
}

nav ul li {
    list-style: none;
    padding-right: 4%;
    color: white;
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 8px;
}

nav ul li button {
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: white;
    border: none;
    background-color: transparent;
    border-bottom: 3px solid transparent;
    padding: 0;
    margin: 0 6px;
}

nav ul li button:hover {
    cursor: pointer;
    color: #F59E0C;
    border-bottom-color: #F59E0C;
}

nav.red ul li button:hover {
    cursor: pointer;
    color: rgb(230, 30, 40);
    border-bottom-color: rgb(230, 30, 40);
}

nav ul li button:after {
    color: #F59E0C;
    cursor: pointer;
}

.head-logo {
    grid-area: left_header;
    display: grid;
    align-content: center;
}


.menu-header-grid {
    grid-area: right_header;
}

.head-edit {
    grid-area: up_header;
    justify-content: right;
    display: flex;
    width: 100%;
}

.head-menu {
    padding: 2% 0%;
    grid-area: down_header;
}

.Hero {
    background-image: url("../images/banner.jpg");
    height: 800px;
    background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
}

.items-box {
    grid-area: items;
    color: white;
    font-weight: 600;
    font-size: 18px;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.owners-box {
    grid-area: owners;
    color: white;
    font-weight: 600;
    font-size: 18px;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.floor_price-box {
    grid-area: floor_price;
    color: white;
    font-weight: 600;
    font-size: 18px;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.vol_traded-box {
    grid-area: vol_traded;
    color: white;
    font-weight: 600;
    font-size: 18px;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.hero-content-wrapper {
    grid-area: jungle_header;
    border-left: 1px solid rgba(255, 255, 255, 0.788);
    margin: 4% 2%;
    padding: 4% 5%;
}

.hamburger {
    display: none;
}

.logo-wrapper {
    display: grid;
    margin: 10%;
    justify-items: center;
}

.section_two_item {
    text-align: left;
    padding: 0% 12%;
}

.hero-btn:hover {
    background-color: #f5750c;
}

.logo-middle {

    position: relative;

}

.section_item {
    text-align: center;
    padding: 5% 18%;
}



.section-icons {
    margin-bottom: 10%;
}



.hero-btn-2:hover {
    background-color: #f5750c;
}

.section-three-images {
    overflow: hidden;
}

.section-three-images img {
    height: 100%;
    width: 100%;
}

.section_four_item {
    text-align: left;
    padding: 15% 15%;
}

.section_five_item {
    text-align: left;
    padding: 15% 4% 15% 12%;
}

.section-five-images {
    display: grid;
    justify-items: center;
}

.section-five-images img {
    width: 90%;
}

.section-six-images {
    height: 250px;
    width: 250px;
    display: grid;
    align-items: center;
}

.section-six-images img {
    height: 100%;
    width: 100%;
}

.section_six_item {
    justify-content: center;
    display: grid;
}

.section_six_item p {
    text-align: center;
}

.section-footer-logo {
    display: grid;
    justify-content: center;
}

.section-footer-icons {
    margin: 4%;
}

.section-footer-item {
    padding-bottom: 4%;
}

.dummy-span {
    display: block;
}


input[type=text] {
    width: 50%;
    padding: 12px 10px;
    margin: 8px 0;
    display: inline-block;
    border: none;
    border-radius: 0px;
    box-sizing: border-box;
}



/* Mobile */
@media only screen and (min-width: 300px) {

    nav ul {
        position: absolute;
        right: 0px;
        padding-right: 10px;
        text-align: right;
        width: 100%;
        z-index: 1;
        transition: ease;
        padding: 0 0 21px 0;
    }

    nav ul li {
        list-style: none;
        padding-right: 4%;
        color: white;
        display: block;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        margin-bottom: 8px;
    }

    .Hero {
        background-image: url("../images/banner.jpg");
        height: 550px;
        overflow: hidden;
        background-repeat: no-repeat;
    }


    .hero-content-wrapper {
        grid-area: jungle_header;
        border-left: none;
        margin: 4% 4%;
        padding: 8% 5% 2% 5%;
        display: grid;
        justify-items: center;
    }

    .hero-hr {
        display: block;
    }

    .hamburger {
        display: grid;
        align-items: center;
        justify-content: right;

    }

    .logo-wrapper {
        display: grid;
        margin: 5%;
        justify-items: center;
    }

    .section_two_item {
        text-align: center;
        display: grid;
        align-items: center;
        justify-content: center;
        height: 140px;
    }

    .dummy-span {
        display: none;
    }

    .section_four_item {
        text-align: left;
        padding: 5% 5%;
    }

    .section_five_item {
        text-align: left;
        padding: 2% 6% 0% 6%;
    }

    .section-five-images {
        display: grid;
        justify-items: center;
    }

    .section-five-images img {
        width: 100%;
    }

    .section-six-images {
        height: auto;
        width: 160px;
        display: grid;
        align-items: center;
    }

    .hero-small-icon {
        display: none;
    }

    input[type=text] {
        width: 50%;
        padding: 12px 10px;
        margin: 8px 0;
        display: inline-block;
        border: none;
        border-radius: 0px;
        box-sizing: border-box;
    }

    .menu-header-grid {
        grid-area: right_header;
    }

    #toogle-menu {
        display: none;
    }

}

/* Tablet */
@media screen and (min-width: 680px) {

    nav ul {
        position: inherit;
        right: 0px;
        padding-right: 10px;
        text-align: right;
        width: 100%;
        z-index: 1;
        transition: ease;
    }

    nav ul li {
        list-style: none;
        padding-right: 4%;
        color: white;
        display: inline-block;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        margin-bottom: 8px;
    }

    .Hero {
        background-image: url("../images/banner.jpg");
        height: 600px;
        overflow: hidden;
        background-repeat: no-repeat;
    }

    .hero-content-wrapper {
        grid-area: jungle_header;
        border-left: 1px solid rgba(255, 255, 255, 0.788);
        border-bottom: none;
        margin: 6% 4%;
        padding: 4% 5%;
        display: grid;
        justify-items: left;
    }

    .hero-hr {
        display: none;
    }

    .hamburger {
        display: none;

    }

    .logo-wrapper {
        display: grid;
        margin: 2.5%;
        justify-items: center;
        position: relative;
        z-index: 1;
    }

    .section_two_item {
        text-align: left;
        display: grid;
        align-items: center;
        justify-content: right;
        height: auto;
    }

    .dummy-span {
        display: block;
    }

    .section_four_item {
        text-align: left;
        padding: 15% 15%;
    }

    .section_five_item {
        text-align: left;
        padding: 5% 5% 0% 5%;
    }

    .section-five-images {
        display: grid;
        justify-items: center;
    }

    .section-five-images img {
        width: 90%;
    }

    .section-six-images {
        height: auto;
        width: 180px;
        display: grid;
        align-items: center;
    }

    .hero-small-icon {
        display: inline;
    }

    input[type=text] {
        width: 100%;
        padding: 12px 10px;
        margin: 8px 0;
        display: inline-block;
        border: none;
        border-radius: 0px;
        box-sizing: border-box;
    }

    .menu-header-grid {
        grid-area: right_header;
    }

    #toogle-menu {
        display: block;
    }

}

@media screen and (min-width: 850px) {

    .logo-wrapper {
        display: grid;
        margin: -2.5%;
        justify-items: center;
        position: relative;
        z-index: 1;
    }

    .Hero {
        background-image: url("../images/banner.jpg");
        height: 700px;
        overflow: hidden;
        background-repeat: no-repeat;
    }

}

@media screen and (min-width: 920px) {

    .logo-wrapper {
        display: grid;
        margin: -2.5%;
        justify-items: center;
        position: relative;
        z-index: 1;
    }

    .Hero {
        background-image: url("../images/banner.jpg");
        height: 700px;
        overflow: hidden;
        background-repeat: no-repeat;
    }

}


@media screen and (min-width: 1080px) {

    .logo-wrapper {
        display: grid;
        margin: -2.5%;
        justify-items: center;
        position: relative;
        z-index: 1;
    }

    .Hero {
        background-image: url("../images/banner.jpg");
        height: 800px;
        overflow: hidden;
        background-repeat: no-repeat;
    }

}

@media screen and (min-width: 2080px) {

    .logo-wrapper {
        display: grid;
        margin: -2.5%;
        justify-items: center;
        position: relative;
        z-index: 1;
    }

    .Hero {
        background-image: url("../images/banner.jpg");
        height: 1080px;
        overflow: hidden;
        background-repeat: unset;
    }

    .hero-content-wrapper {
        grid-area: jungle_header;
        border-left: 2px solid rgba(255, 255, 255, 0.788);
        border-bottom: none;
        margin: 4% 4%;
        padding: 3% 5%;
        display: grid;
        justify-items: left;
    }

}