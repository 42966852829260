body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Furore';
  font-style: normal;
  font-weight: normal;
  src: local('Furore'), url('./static/fonts/Furore.woff') format('woff');
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'IBM Plex Mono Regular';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Regular'), url('./static/fonts/IBMPlexMono-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Italic'), url('./static/fonts/IBMPlexMono-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Thin';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Thin'), url('./static/fonts/IBMPlexMono-Thin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Thin Italic'), url('./static/fonts/IBMPlexMono-ThinItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono ExtraLight'), url('./static/fonts/IBMPlexMono-ExtraLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono ExtraLight Italic'), url('./static/fonts/IBMPlexMono-ExtraLightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Light';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Light'), url('./static/fonts/IBMPlexMono-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Light Italic'), url('./static/fonts/IBMPlexMono-LightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Medium';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Medium'), url('./static/fonts/IBMPlexMono-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Medium Italic'), url('./static/fonts/IBMPlexMono-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono SemiBold'), url('./static/fonts/IBMPlexMono-SemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono SemiBold Italic'), url('./static/fonts/IBMPlexMono-SemiBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Bold';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Bold'), url('./static/fonts/IBMPlexMono-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'IBM Plex Mono Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Mono Bold Italic'), url('./static/fonts/IBMPlexMono-BoldItalic.woff') format('woff');
  }